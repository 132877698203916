<template>
  <div class="container">
    <div v-if="show">
      <el-input
        v-model="newTitle"
        type="textarea"
        class="new-task-title"
        autosize
        autofocus
        placeholder="Enter a title for this card..."
        @blur="!newTitle ? show = false : show = true"
        @keydown.enter.native.prevent="addNewTask"
      />
      <el-button
        type="primary"
        size="mini"
        @click="addNewTask"
      >
        Add task
      </el-button>
      <el-button
        size="mini"
        type="danger"
        plain
        @click="close"
      >
        <i class="el-icon-close" />
      </el-button>
    </div>

    <el-button
      v-if="!show"
      type="primary"
      size="mini"
      @click="show = true"
    >
      Add task
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    showInput: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      show: this.showInput,
      newTitle: '',
    };
  },
  methods: {
    addNewTask() {
      if (!this.showInput) this.show = true;
      if (!this.newTitle) return;

      this.$emit('add-task', this.newTitle);
      this.newTitle = '';
    },
    close() {
      this.newTitle = '';
      this.show = false;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 10px 0;
}
.new-task-title {
  padding-bottom: 10px;
}
</style>
